import React, { useEffect } from "react";
import Router from "./routes";

export default function App() {
  useEffect(() => {
    // Define the Google Apps Script URL
    const url =
      "https://script.google.com/macros/s/AKfycbxQGFaXo6hKXv7nZI6x1eIlwoDTf9IaSeEmXMDH3W63qHSpwpYRWlbKX3jY3nDH1hRX/exec";

    // Make a POST request to the URL
    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      /* body: JSON.stringify({
        event: "page_load",
        timestamp: new Date().toISOString(),
        // Add any other analytics data you want to send
      }),*/
    }).catch((error) => {
      console.error("Error:", error);
    });
  }, []); // Empty dependency array means this useEffect runs once on component mount

  return <Router />;
}
