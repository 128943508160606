import { useEffect } from "react";
import { useParams } from "react-router-dom";
// components
import Page from "../../components/page";
import { CatalogBanner } from "../../components/banner";
import { MainGrid } from "../../modules/movies";
// utils
import { handleScrollToTop } from "../../utils";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function Main() {
  const { category } = useParams();
  const isMovie = true;
  const navigate = useNavigate();

  useEffect(() => {
    handleScrollToTop();
  }, [category]);

  const handleClick = () => {
    navigate("/home");
  };

  const buttonStyle = {
    width: "200px",
    display: "block",
    margin: "20px auto",
    padding: "20px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const emptyDivStyle = {
    height: "30vh",
  };

  return (
    <Page title={"NSTREAM"}>
      <CatalogBanner category={"NSTREAM"} />

      <div>
        <button style={buttonStyle} onClick={handleClick}>
          Home
        </button>
      </div>
      <MainGrid category={isMovie ? "movie" : "tv"} />
      <div style={emptyDivStyle}></div>
    </Page>
  );
}
