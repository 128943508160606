import { EMBED_URL } from "../config";

// ----------------------------------------------------------------------

const embedMovie = (id) => `${EMBED_URL}/movie/${id}`;
const embedEpisode = (id, season, episode) =>
  `${EMBED_URL}/tv/${id}/${season}/${episode}`;
///

export { embedMovie, embedEpisode };
